/* This example requires Tailwind CSS v2.0+ */
import React from "react"
//import { StaticImage } from "gatsby-plugin-image"
import KdpImage from "../images/demarche-qualite.jpg"



export default function Example() {
  return (


    <svg
      className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 bg bg-[url('../images/ceos-pattern.png')] grayscale brightness-200 opacity-20"
      width={404}
      fill="none"
      viewBox="0 0 404 4000"
      aria-hidden="true"
    >
    </svg>


  )
}


import React, { useRef } from 'react';
import { Helmet } from "react-helmet"
import KdpHeader from "/src/blocs/Header/Header"
import KdpFooter from "/src/blocs/Footer/Footer"
import KdpAriane from "/src/blocs/Ariane"
import KdpPaterneCeos from "/src/blocs/PaterneCeos"
import KdpImage from "/src/images/demarche-qualite.jpg"

const ariane = [
  { name: 'Préventica', href: '', current: true },
]


export default function DemarcheQualite() {





  return (
    <div className="bg-white">
      <Helmet>
        <title>Démarche qualité de CEOS Avocats</title>
        <meta name="description" content="Accessibilité et disponibilité, engagement qualité, transparence et validation client" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane
          filariane={ariane}
        />




        <div className="bg-gray-50 overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">

            <KdpPaterneCeos />

            <div className="relative">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">

                <div className="relative sm:py-16 lg:py-0">
                  <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                    <div className="absolute inset-y-0 right-1/2 w-full bg-gray-100 rounded-r-3xl lg:right-72" />

                  </div>
                  <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">

                    <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">


                      <div>
                        <video controls autoplay className="absolute inset-0 h-full h-auto object-cover" >
                          <source src="/preventica_video.mp4" type="video/mp4" />
                          Votre navigateur ne supporte pas le lecteur vidéo.
                        </video>

                      </div>


                    </div>
                  </div>
                </div>

                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">


                  {/* Content area */}
                  <div className="pt-12 sm:pt-16 lg:pt-20">



                    <h1 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                      Merci d’avoir visité notre stand au salon Préventica Lyon 2024 !
                    </h1>

                    <div className="mt-6 text-gray-500 space-y-6">
                      <p className="text-base leading-7 text-justify">
                        Nous espérons que vous avez pris plaisir à nous rencontrer et que nous vous avons apporté les informations dont vous aviez besoin.

                        N’hésitez pas à <a className="underline" href="/nous-contacter/">nous contacter</a> si vous avez des questions ou besoin d’un complément d’informations, notre équipe se fera un plaisir de vous répondre et vous accompagner.
                      </p>

                    </div>
                  </div>


                </div>


              </div>
            </div>
          </div>
        </div>




      </main>
      <footer>
        <KdpFooter />
      </footer>
    </div>
  );
}
